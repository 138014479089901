import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Add the import
import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
  window.console.log = function () {}; // disable any console.log debugging statements in production mode
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log('err->', err));

// Call the loader
defineCustomElements(window);
