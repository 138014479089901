import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
import {
  LoadingController,
  ToastController,
  NavController,
} from '@ionic/angular';
import { UtilService } from 'src/app/services/util/util.service';
import { SceService } from '../sce.service';
import { User } from 'src/app/models/user';

@Injectable()
export class InitUserProvider {
  private loggedInUser: User;
  private checkSignedInUserInterv;
  constructor(
    private api: APIService,
    private loadingCtrl: LoadingController,
    public util: UtilService,
    private toastCtrl: ToastController,
    private sce: SceService,
    private navCtrl: NavController
  ) {
    this.createNewEmptyUser();
  }

  getUserData(): User {
    return this.loggedInUser;
  }

  createNewEmptyUser() {
    this.loggedInUser = {
      id: null,
      name: '',
      email: '',
      phone: '',
      gender: '',
      dob: '',
      password: '',
      location_lat: 0,
      location_lng: 0,
      token: '',
      profile_img: '',
      rideId: 0,
      location: '',
      home: '',
      work: '',
      isChecked: false,
    };
  }

  load() {
    console.log('loaded ------');
    return new Promise((resolve, reject) => {
      this.getToken().then((token) => {
        this.api.updateToken(token);
        this.api.getUser().subscribe(
          (user: any) => {
            if (user) {
              this.sce._loggedInUser = user;
              this.setLoggedInUser(user);
              resolve(true);
            } else resolve(false);
          },
          (err) => {
            resolve(false);
            console.log(err);
          }
        );
      });
    });
  }

  async setToken(token) {
    this.api.updateToken(token);
    await localStorage.setItem('adminToken', token);
  }

  async getToken() {
    const token = await localStorage.getItem('adminToken');
    return token;
  }

  async setLoggedInUser(user: any) {
    Object.assign(this.loggedInUser, user);
    this.loggedInUser.token = await this.getToken();
    await localStorage.setItem('adminId', user.id);
    this.sce._loggedInUser = this.loggedInUser;
    if (this.loggedInUser.roll == 'admin') {
      this.sce.isAdmin = true;
    } else {
      this.sce.isAdmin = false;
    }
    //  this.initSession();
  }

  initSession() {
    if (!this.sce._session) {
      this.sce._loggedInUser.signInToken = this.sce.signInToken;
      this.api
        .updateUser(this.sce._loggedInUser.id, this.sce._loggedInUser)
        .subscribe(async (updatedUser) => {
          this.checkSignedInUserInterv = setInterval(() => {
            this.checkSignedInUser();
          }, 5000);
        });
    }
  }

  checkSignedInUser() {
    if (!this.loggedInUser.id) clearInterval(this.checkSignedInUserInterv);
    else {
      this.api.getUser().subscribe(
        (res: any) => {
          if (
            res &&
            (!res.signInToken || res.signInToken !== this.sce.signInToken)
          ) {
            clearInterval(this.checkSignedInUserInterv);
            this.navCtrl.navigateRoot('/loginPage');
          }
        },
        (err) => console.log('3 - checkSignedInUser===>', err)
      );
    }
  }

  onNgDestroy() {
    clearInterval(this.checkSignedInUserInterv);
  }

  async logout(): Promise<any> {
    this.createNewEmptyUser();
    await this.api.logout();
    await localStorage.removeItem('adminToken');
    return localStorage.clear();
  }

  async createLoader(message): Promise<HTMLIonLoadingElement> {
    const loader = await this.loadingCtrl.create({
      message,
    });
    return loader;
  }

}
