import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'meal',
        pathMatch: 'full',
    },
    {
        path: 'meal',
        loadChildren: () =>
            import('./resto/resto-sce.module').then((m) => m.RestoAppModule),
    },
    {
        path: 'loginPage',
        loadChildren: () =>
            import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'register',
        loadChildren: () =>
            import('./pages/register/register.module').then((m) => m.RegisterPageModule),
    },
    {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
    },
    {
        path: 'ingredients',
        loadChildren: () =>
            import('./pages/ingredients/ingredients.module').then(
                (m) => m.IngredientsModule
            ),
    },
    {
		path: '**',
		redirectTo: 'meal',
		pathMatch: 'full',
	},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
