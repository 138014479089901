import { Injectable } from '@angular/core';

import {
  AlertController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { environment } from 'src/environments/environment';

declare let google;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private navCtrl: NavController
  ) { }

  goToNew(route) {
    this.navCtrl.navigateRoot(route);
  }

  goForward(page) {
    this.navCtrl.navigateForward(page);
  }

  async createAlert(
    header,
    backdropDismiss,
    message,
    buttonOptions1,
    buttonOptions2?
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2
        ? [buttonOptions1]
        : [buttonOptions1, buttonOptions2],
    });
    return alert;
  }

  async createToast(
    message,
    showCloseButton = false,
    position = 'bottom' as 'top' | 'bottom' | 'middle',
    duration = 1500
  ): Promise<HTMLIonToastElement> {
    const toast = await this.toastCtrl.create({
      message,
      position: 'middle',
      duration,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          },
        },
      ],
    });
    return toast;
  }

  async showMsg(msg) {
    const toast = await this.createToast(msg, false, 'middle');
    await toast.present();
  }

  async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
    const service = new window['google'].maps.places.AutocompleteService();
    let pred;
    // var circle = new google.maps.Circle(
    //     {center: geolocation, radius: 10000});
    // autocomplete.setBounds(circle.getBounds());
    await new Promise((resolve, reject) => {
      service.getPlacePredictions(
        {
          input: searchText,
          componentRestrictions: { country: country || environment.COUNTRY },
        },
        (predictions) => {
          pred = predictions;
          resolve(true);
        }
      );
    });
    return pred;
  }
}
