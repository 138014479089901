import { Injectable } from '@angular/core';
import { finalize, map, tap } from 'rxjs/operators';
import { APIService } from './api/api.service';
import { MyData } from 'src/app/resto/pages/home/home.page';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { combineLatest, Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SceService {
  data: any = {
    categories: [],
    parentMeals: [],
    meals: [],
    ingredients: [],
    vendors: [],
    zone: [],
    vendorHub: [],
  };
  _spinner = false;
  mealsByParentMeal;
  mealsByVendor;
  mealsByCategory;
  mealsByAlphabetical;
  mealsByOrder;

  isAdmin = true;
  _loggedInUser: any;
  _session = null;
  signInToken = '';

  restoCat = [];
  restoList: any = {};
  restoItems: any = [];
  restoSelectedItem: any;
  _restoCheckout = false;
  groupedPopularItems;
  groupedPopularItemsLength;
  itemsList: any[] = [];
  _selectedRestoId;
  _itemsLoadingSpinner: boolean = false;
  comingFromPopularItems: boolean = false;
  menuOpen: boolean = false;
  selectedMenuIndex;
  menuItemsList: any[] = [];
  action = '';
  editItem: any;
  _editItem: boolean = false;
  _selectedItemIndex: number;
  _itemSaved = false;
  showAdditional: boolean = false;
  _savingProductSpinner: boolean = false;

  currentCatId;
  currentRestoId;

  //Status check
  isUploading: boolean;
  isUploaded: boolean;

  //File details
  fileName: string;
  fileSize: number;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  UploadedFileURL: Observable<string>;
  private imageCollection: AngularFirestoreCollection<MyData>;

  restaurantId;
  selectedRestoId;
  selectedVendor;
  selectedVendorIndex;
  _switch = 'vendor';
  startUpload: boolean = false;

  constructor(
    private apiService: APIService,
    private storage: AngularFireStorage,
    private database: AngularFirestore,
    private alertCtrl: AlertController
  ) {}

  uploadFile(event: FileList) {
    console.log('event -> ', event);
    const file = event.item(0); // The File object
    if (file.type.split('/')[0] !== 'image') {
      // Validation for Images Only
      console.error('unsupported file type :( ');
      return;
    }

    this.isUploading = true;
    this.isUploaded = false;
    this.fileName = file.name;
    const path = `meals/${new Date().getTime()}_${file.name}`; // The storage path
    const customMetadata = { app: 'BW resto image upload' }; // Totally optional metadata
    const fileRef = this.storage.ref(path); //File reference
    this.task = this.storage.upload(path, file, { customMetadata }); // The main task
    this.percentage = this.task.percentageChanges(); // Get file progress percentage
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(() => {
        // Get uploaded file storage path
        console.log('file -->', this.fileName);
        if (this.fileName) {
          this.UploadedFileURL = fileRef.getDownloadURL();
          this.UploadedFileURL.subscribe(
            (resp) => {
              this.addImagetoDB({
                name: file.name,
                filepath: resp,
                size: this.fileSize,
              });
              this.isUploading = false;
              this.isUploaded = true;
              this.editItem.thumbnail = resp; // save image link
              this._itemSaved = true;
              this.fileName = '';
              console.log('image url -->>', this.editItem);
            },
            (error) => {
              console.error(error);
            }
          );
        }
      }),
      tap((snap) => {
        this.fileSize = snap.totalBytes;
      })
    );
  }

  addImagetoDB(image: MyData) {
    //Create an ID for document
    const id = this.database.createId();

    //Set document id with value in database
    this.imageCollection
      .doc(id)
      .set(image)
      .then((resp) => {
        console.log('upload -->>', resp);
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  }

  editItemData(data: any, index: number) {
    console.log('edit p ID -> ', data, index);
    this.action = 'edit';
    this.editItem = Object.assign(
      {},
      this.itemsList.find((x) => x.id === data.id)
    );
    this._editItem = true;
    this._selectedItemIndex = index;
    this._itemSaved = false;
    console.log('Edit Item-->>', index, this.editItem);
  }

  closeInput() {
    this.showAdditional = false;
  }

  showInput() {
    this.showAdditional = true;
  }

  async activateMenuItem(data: any, index: number) {
    let foundIndex = this.itemsList.findIndex((x) => x.id === data.id);
    this.restoItems.items = this.itemsList;
    this.restoItems.items[foundIndex].active = true;

    this.apiService
      .updateDataApi('resto-items', this._selectedRestoId, this.restoItems)
      .subscribe((res) => {
        console.log('update Data Res -> ', res);
      });
  }

  openMenuItems(i: number) {
    console.log('openMenu i -> ', i);
    this.comingFromPopularItems = false;
    if (this.comingFromPopularItems) {
      this.openPopularItems();
    } else {
      this.menuOpen = true;
      this.selectedMenuIndex = i;
      if (this.data.groupedMenus[i]) {
        this.menuItemsList = [this.data.groupedMenus[i]];
      } else {
        this.menuOpen = false;
      }
    }
    this.comingFromPopularItems = false;
    console.log('menu list ->', this.menuItemsList);
  }

  openPopularItems() {
    this.selectedMenuIndex = null;
    this.menuOpen = true;
    if (this.groupedPopularItems) {
      this.menuItemsList = this.groupedPopularItems;
      this.comingFromPopularItems = true;
    } else {
      this.menuOpen = false;
    }
  }

  groupByMenus(list, keyGetter) {
    const map = new Map();
    const objects = [];
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  async createAlert(
    header,
    backdropDismiss,
    message,
    buttonOptions1,
    buttonOptions2?
  ): Promise<HTMLIonAlertElement> {
    const alert = await this.alertCtrl.create({
      header,
      backdropDismiss,
      message,
      buttons: !buttonOptions2
        ? [buttonOptions1]
        : [buttonOptions1, buttonOptions2],
    });
    return alert;
  }

  getAllMealData() {
    console.log('get all data ->>>> >> >> >>');
    this._spinner = true;
    return new Promise<any>((resolved, rejected) => {
      return combineLatest([
        this.apiService.getOrderedList('categories'),
        this.apiService.getOrderedList('parentMeals'),
        this.isAdmin
          ? this.apiService.getList('meals')
          : this.apiService.getListByVendorId('meals', this._loggedInUser),
        this.apiService.getVendorList('vendors', 'vendor'),
        this.apiService.getUsersList('users', 'admin'),
        this.apiService.getOrderedList('ingredients'),
        this.apiService.getZoneList('zone'),
        this.apiService.getList('users'),
      ]).subscribe(
        (res: any) => {
          this.data = {
            categories: res[0],
            parentMeals: res[1],
            meals: res[2],
            vendors: res[3],
            users: res[4],
            ingredients: res[5],
            zone: res[6],
            allUsers: res[7],
            groupedMenus: [],
            assembledCat: [],
          };
          this._spinner = false;
          this.assembleMealData();
          resolved(true);
        },
        (err) => {
          this._spinner = false;
          console.log('err get all meal data ->', err);
        }
      );
    });
  }

  // Attach Categories to parent / Meals /vendors
  assembleMealData() {
    let foundIndex;
    let parentObject: any = [];
    let mealObject: any;
    this.data.assembledCat = [];
    let catHasParent;
    this.data.categories.forEach((cat, catIndex) => {
      this.data.assembledCat[catIndex] = cat;
      this.data.assembledCat[catIndex].parentMeals = [];
      this.data.parentMeals.forEach((parent, parendIndex) => {
        catHasParent = false;
        if (parent.categories && parent.categories.length > 0) {
          parent.categories.forEach((catId) => {
            if (cat.id === catId) {
              catHasParent = true;
              parentObject = Object.assign({}, parent);
              parentObject.childMeals = [];
              this.data.meals.forEach((meal, mealIndex) => {
                if (meal.parentMealId === parent.id) {
                  mealObject = Object.assign({}, meal);
                  foundIndex = this.data.vendors.findIndex(
                    (x) => x.id === meal.vendorId
                  );
                  mealObject.vendor =
                    foundIndex >= 0 ? this.data.vendors[foundIndex] : {};
                  parentObject.childMeals.push(mealObject);
                }
              });
            }
          });
        }
        // if (parentObject.childMeals && parentObject.childMeals.length > 0)
        if (catHasParent)
          this.data.assembledCat[catIndex].parentMeals.push(parentObject);
      });
    });

    // calc counters
    let totalMealsbyCat;
    this.data.assembledCat.forEach((cat) => {
      totalMealsbyCat = 0;
      cat.parentCount = cat.parentMeals ? cat.parentMeals.length : 0;
      if (cat.parentMeals) {
        cat.parentMeals.forEach((parent) => {
          parent.mealsCounterByParent = parent.childMeals
            ? parent.childMeals.length
            : 0;
          totalMealsbyCat += parent.mealsCounterByParent;
        });
        cat.mealsCountBycat = totalMealsbyCat;
      }
    });

    // zone-hub list
    this.findAllZoneHub();
    console.log('Assembled categories/parents/meals =>', this.data);
  }

  findAllZoneHub() {
    this.data.vendorHub = [];
    if (this.data.zone && this.data.zone.length > 0) {
      this.data.zone.forEach((el) => {
        el.hubs.forEach((hub) => {
          this.data.vendorHub.push({
            zoneHub: el.zone + '|' + hub.num + '|' + hub.address,
            zoneId: el.id,
            zone: el.zone,
            hub: hub.num,
            hubAddress: hub.address,
          });
        });
      });
    }
    console.log('vendor hub -->', this.data.vendorHub);
  }

  onSort(value) {
    console.log('value', value);

    this.resetSortingOptions();
    switch (value) {
      case 'parentMeals': {
        this.sortByParentMeal();
        break;
      }
      case 'vendor': {
        this.sortByVendor();
        break;
      }
      case 'categories': {
        this.sortByCategory();
        break;
      }
      case 'asc': {
        this.sortByAlphabetical('asc');
        break;
      }
      case 'desc': {
        this.sortByAlphabetical('desc');
        break;
      }
    }
  }

  sortByAlphabetical(direction: 'asc' | 'desc') {
    let meals = this.data.meals;
    if (direction == 'asc') {
      meals.sort((a, b) => b.name.localeCompare(a.name));
    } else {
      meals.sort((a, b) => a.name.localeCompare(b.name));
    }
    this.mealsByAlphabetical = meals;
  }

  sortByVendor() {
    let meals = this.data.meals;
    let vendors = this.data.vendors;
    vendors.forEach((vendor) => {
      const foundMeals = meals.filter((meal) => meal.vendorId === vendor.id);

      vendor.childMeals = foundMeals;
    });

    this.mealsByVendor = vendors;
    console.log('meals / Vendors -> ', this.mealsByVendor);
  }

  sortByCategory() {
    let meals = this.data.meals;
    let parentMeals = this.data.parentMeals;
    let categories = this.data.categories;

    categories.forEach((category) => {
      parentMeals.forEach((parentMeal) => {
        if (parentMeal.categories) {
          const foundMeal = parentMeal.categories.find(
            (mealCategory) => mealCategory === category.id
          );
          if (foundMeal) {
            category.childMeals = meals.filter(
              (meal) => meal.parentMeal.id === parentMeal.id
            );
          }
        }
      });
    });

    this.mealsByCategory = categories;
  }

  resetSortingOptions() {
    this.mealsByVendor = [];
    this.mealsByParentMeal = [];
    this.mealsByCategory = [];
    this.mealsByAlphabetical = [];
  }

  private sortByParentMeal() {
    let parentMeals = this.data.parentMeals;
    let meals = this.data.meals;
    let vendors = this.data.vendors;
    let assembledParentMeals = [];

    meals.forEach((meal) => {
      const foundVendor = vendors.find((vendor) => vendor.id === meal.vendorId);
      meal.vendor = foundVendor;
    });

    parentMeals.forEach((parentMeal) => {
      const foundMeals = meals.filter(
        (meal) => meal.parentMeal.id === parentMeal.id
      );

      parentMeal.childMeals = foundMeals;

      if (parentMeal.childMeals && parentMeal.childMeals.length > 0)
        assembledParentMeals.push(parentMeal);
    });

    this.mealsByParentMeal = assembledParentMeals;
  }
}
