<ion-app>
  <ion-menu contentId="main-content">
    <ion-content class="col-y">
      <ion-menu-toggle auto-hide="false">
        <ion-row
          (click)="sce._switch = 'vendor'; openPage('meal/home', 'vendor')"
          class="cursor"
        >
          <ion-col size="1.5" class="text-center">
            <ion-icon name="people"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Vendors </ion-col>
        </ion-row>



        <ion-row
          (click)="sce._switch = 'usersList'"
          class="cursor"
        >
          <ion-col size="1.5" class="text-center">
            <ion-icon name="order"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Users </ion-col>
        </ion-row>
        <ion-row
          (click)="sce._switch = 'report'"
          class="cursor"
        >
          <ion-col size="1.5" class="text-center">
            <ion-icon name="order"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Orders </ion-col>
        </ion-row>

        <ion-row
          (click)="sce._switch = 'category'; openPage('meal/home', 'category')"
          class="cursor"
        >
          <ion-col size="1.5" class="text-center">
            <ion-icon name="restaurant"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Category </ion-col>
        </ion-row>

        <!-- <ion-row (click)="sce._switch = 'parent'; openPage('meal/home', 'parent')" class="cursor">
              <ion-col size="1.5"  class="text-center">
                <ion-icon name="restaurant"></ion-icon>
              </ion-col>
              <ion-col size="10.5">
                  Menu Section
              </ion-col>
            </ion-row> -->

        <ion-row
          (click)="openPage('ingredients', 'ingredients')"
          class="cursor"
        >
          <ion-col size="1.5" class="text-center">
            <ion-icon name="apps"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Ingredients </ion-col>
        </ion-row>

        <ion-row (click)="openPage('register')" class="cursor" class="cursor">
          <ion-col size="1.5" class="text-center">
            <ion-icon name="person"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Admin </ion-col>
        </ion-row>

        <ion-row (click)="logout()">
          <ion-col size="1.5" class="text-center" class="cursor">
            <ion-icon name="log-out"></ion-icon>
          </ion-col>
          <ion-col size="10.5"> Logout </ion-col>
        </ion-row>
      </ion-menu-toggle>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
