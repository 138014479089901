import { Component, Pipe, PipeTransform } from '@angular/core';
import { Platform } from '@ionic/angular';

import { UtilService } from './services/util/util.service';
import { SceService } from './services/sce.service';
import { InitUserProvider } from './services/inituser/inituser.service';
import { User } from './models/user';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user: User;

  constructor(
    private userProvider: InitUserProvider,
    private platform: Platform,
    private util: UtilService,
    public sce: SceService
  ) {
    console.log('load app component');
    this.user = this.userProvider.getUserData();
  }

  logout() {
    this.userProvider.logout().then((res) => {
      this.util.goToNew('/loginPage');
    });
  }

  openPage(page, dt?: string) {
    this.util.goForward(page);
  }
}
