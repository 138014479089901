import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { FirestoreService } from "./firestore.service";
import { AuthenticationService } from "./firebase-authentication.service";
import firebase from "firebase/compat/app";

@Injectable({
  providedIn: "root",
})
export class APIService {
  private id;

  constructor(
    private http: HttpClient,
    private firestore: FirestoreService,
    private auth: AuthenticationService
  ) {}

  updateToken(id) {
    this.id = id;
  }

  signUpVendor(vendor): Observable<any> {
    return new Observable((observer) => {
      this.auth
        .createAccount(vendor.email, vendor.password)
        .then((User) => {
          if (vendor.password) {
            delete vendor.password;
          }
          vendor.roll = "vendor";
          vendor.id = User.uid;
          console.log("create acount user -> ", vendor);
          this.firestore.createWithId("vendors", vendor).then(
            (usr) => {
              // console.log(usr);
              observer.next(vendor);
            },
            (err) => {
              observer.error(err);
            }
          );
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  signUpAdmin(user): Observable<any> {
    return new Observable((observer) => {
      this.auth
        .createAccount(user["email"], user["password"])
        .then((User) => {
          console.log("create acount user -> ", User);
          user.id = User.uid;
          if (user.password) delete user.password;
          this.firestore.createWithId("users", user).then(
            (usr) => {
              observer.next(user);
            },
            (err) => {
              observer.error(err);
            }
          );
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  logIn(username: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.auth
        .login(username, password)
        .then((user) => {
          observer.next({ id: user.uid });
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  logout() {
    return this.auth.logout();
  }

  getList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy("createdAt", "desc")
    );
  }

  getOrderedList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy("order", "asc")
    );
  }

  getZoneList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) => ref);
  }

  getVendorList(collection, search): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.where("roll", "==", search).orderBy("createdAt", "desc")
    );
  }

  getUsersList(collection, search): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.where("roll", "==", search).orderBy("createdAt", "desc")
    );
  }

  getAllUsersList(collection): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy("createdAt", "desc")
    );
  }

  getListByVendorId(collection, vendorId): Observable<any> {
    return this.firestore.find(collection, (ref) =>
      ref.orderBy("createdAt", "desc").where("vendorId", "==", vendorId)
    );
  }

  getUser(): Observable<any> {
    // console.log('id', this.id);
    return this.addIdToObject(this.id, this.firestore.getOne("users", this.id));
  }

  getDriver(driverId): Observable<any> {
    return this.addIdToObject(
      driverId,
      this.firestore.getOne("drivers", driverId)
    );
  }

  updateUser(id, userData): Observable<any> {
    //console.log('updateuser', userData);
    return from(this.firestore.update("vendors", id, userData));
  }

  getRestoListApi(dbDoc: string, searchStr: string): Observable<any> {
    return this.firestore.find(dbDoc, (ref) =>
      ref.where("category_id", "==", searchStr)
    );
  }

  bookRide(rideData: any): Observable<any> {
    return this.snapshotToDataConverter(
      this.firestore.create("rides", rideData)
    );
  }

  createDbCollection(db: string, data: any): Observable<any> {
    return this.snapshotToDataConverter(this.firestore.create(db, data));
  }

  getDbCollection(db, id): Observable<any> {
    return this.addIdToObject(id, this.firestore.getOne(db, id));
  }

  updateDbCollection(db, id, data): Observable<any> {
    return from(this.firestore.update(db, id, data));
  }

  getRide(rideId): Observable<any> {
    return this.addIdToObject(rideId, this.firestore.getOne("rides", rideId));
  }

  getdishTags(id): Observable<any> {
		return this.firestore.find('dishTags', (ref) =>
			ref
				.where('dishId', '==', id)
				.orderBy('createdAt', 'asc')
				
		)
	}


  getLocationTags(locationIds): Observable<any> {
    if(locationIds?.length<0){
      return 
    }
		return this.firestore.find('locationTags', (ref) =>
			ref
				.where('locationID', 'in', locationIds)
				.orderBy('createdAt', 'asc')
				
		)
	}

   chunkArray(array: any[], size: number): any[][] {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  }
  
  async  getDishTags(itemIds: string[]) {
    try {
      // Split itemIds and locationIds into batches of 30 (Firestore limit)
      const itemChunks = this.chunkArray(itemIds, 30);
  
      // Process each batch for dishTags
      const itsmSnapshotPromises = itemChunks.map(async (chunk) => {
        return await firebase
          .firestore()
          .collection("dishTags")
          .where("dishId", "in", chunk)
          .get();
      });
  
  
  
      // Wait for all dishTags queries to complete
      const itsmSnapshots = await Promise.all(itsmSnapshotPromises);
  
      // Wait for all locationTags queries to complete
  
      // Combine and extract data from all dishTags results
      const itsmResults = itsmSnapshots.reduce((acc, snap) => {
        const data = snap.docs.map((doc) => doc.data());
        return acc.concat(data);
      }, []);
  
     
  
      // Return the combined results
      return itsmResults ;
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  }


  updateRideData(rideId, data): Observable<any> {
    return from(this.firestore.update("rides", rideId, data));
  }

  setRideTimeOut(rideId): Observable<any> {
    return new Observable((observer) => {
      this.getRide(rideId).subscribe(
        (ride) => {
          if (!ride["ride_accepted"]) {
            this.updateRideData(rideId, { request_timeout: true }).subscribe(
              (res) => {
                observer.next({ message: [1] });
              },
              (err) => {
                observer.next({ message: [0] });
              }
            );
          } else {
            observer.next({ message: [0] });
          }
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  setRideRejected(rideId): Observable<any> {
    return this.updateRideData(rideId, { user_rejected: true });
  }

  // getRideHistory(userId): Observable<any> {
  //   return this.firestore.runQuery('rides', { field: 'clientId', operation: '==', searchKey: userId });
  // }

  updateDataApi(collection: string, id: string, data: any): Observable<any> {
    console.log("update data", data);
    return from(this.firestore.update(collection, id, data));
  }

  updateOrdersApi(collection: string, id: string, data: any): Observable<any> {
    console.log("update data", data);
    return from(this.firestore.update(collection, id, data));
  }

  saveDataApi(collection: string, data: any): Observable<any> {
    console.log("save data", data);
    return from(this.firestore.create(collection, data));
  }

  delDataApi(collection: string, id: string): Observable<any> {
    console.log("delete", id);
    return from(this.firestore.delete(collection, id));
  }

  getRestoItems(dbDoc: string, searchStr: string): Observable<any> {
    return this.firestore.find(dbDoc, (ref) =>
      ref.where("resto_id", "==", searchStr)
    );
  }

  getRideHistory(userId, recordsNbr): Observable<any> {
    return this.firestore.find("rides", (ref) =>
      ref
        .where("clientId", "==", userId)
        .where("ride_completed", "==", true)
        .orderBy("createdAt", "desc")
        .limit(recordsNbr)
    );
  }

  getVendorAccounts(restoId): Observable<any> {
    return this.firestore.find("vendors", (ref) =>
      ref.where("store_id", "==", restoId).limit(50)
    );
  }

  snapshotToDataConverter(
    query: Promise<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >
  ) {
    return new Observable((observer) => {
      query
        .then((ref) => {
          const obj = ref.data();
          obj.id = ref.id;
          observer.next(obj);
        })
        .catch((err) => {
          observer.error(err);
        });
    });
  }

  addIdToObject(id, obj: Observable<any>) {
    return new Observable((observer) => {
      if (id) {
        obj.subscribe(
          (ref) => {
            const newObj = ref;
            if (newObj) {
              newObj.id = id;
            }
            observer.next(newObj);
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.error({ message: "No ID" });
      }
    });
  }
}
