import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';

import { AuthenticationService } from './services/api/firebase-authentication.service';
import { APIService } from './services/api/api.service';
import { FirestoreService } from './services/api/firestore.service';

import { StorageService } from './services/api/firestorage.service';
import { InitUserProvider } from './services/inituser/inituser.service';

import { DatePipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

//import { CsvConfirmationComponent } from './components/csv-confirmation/csv-confirmation.component';
//import { Angular2CsvModule } from 'angular2-csv';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.config)),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.config),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    // Angular2CsvModule,
  ],
  providers: [
    Geolocation,
    AuthenticationService,
    FirestoreService,
    APIService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    InitUserProvider,
    StorageService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initUserProviderFactory,
      deps: [InitUserProvider],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function initUserProviderFactory(provider: InitUserProvider) {
  return () => provider.load();
}
